import React, { Component } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import Footer from '../../components/footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from '../../components/ImageGallery/slides';

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


class Images extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      'IMG_0141.JPG',
      'IMG_0143.JPG',
      'IMG_0144.JPG',
      'IMG_0145.JPG',
      'IMG_0146.JPG',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings}>
          {imageNames.map((imageName, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              imgSrc={`/img/gallery/10closet/${imageName}`}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/10closet/IMG_0144.JPG)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>UHF Closet</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <Link className='link' to="/projects">Projects</Link>
                <span className='dash'>/</span>
                <span>UHF Closet</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="volunteer-container">
      <div className="card">
        <img src="/img/gallery/10closet/closet.png" className="card__image" alt="brown couch" />
        <div className="card__content">
          <span className="card__title">Sign up Now!</span>
          <span className="card__date">All clean, gently used and new clothing, coats, shoes, houseware, books accepted. Other donations we are in need of are: new hygiene products that include toothbrushes, toothpaste,  soap, shampoo, conditioner, lotion, adult and baby diapers, etc. are accepted!</span>
          <div className='navbar-item'>
            <NavLink to="/donate">
              <span className="donate-button" role="button">Click here to sign up</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>

    <section className='container-fluid' id="service">
      <div className='row m-10-hor'>
        <div className='col-md-4'>
          <iframe 
            src="https://donorbox.org/embed/donate-to-uhf-1?" 
            name="donorbox" 
            title='donate'
            style={{width:'100%', height:'700px', minHeight:'690px', backgroundColor:'white'}}>
          </iframe> 
        </div>
        <div className='col-md-8'>
          <Images />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
