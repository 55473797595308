import React, { Component } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import Footer from '../../components/footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from '../../components/ImageGallery/slides';


class Images extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      'IMG_0438.JPG',
      'IMG_0605.JPG',
      'IMG_0701.JPG',
      'IMG_0790.JPG',
      'IMG_0793.JPG',
      'IMG_0797.JPG',
      'IMG_0262.JPG',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings}>
          {imageNames.map((imageName, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              imgSrc={`/img/gallery/9water/${imageName}`}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/9water/IMG_0605.JPG)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>Water Distribution</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <Link className='link' to="/projects">Projects</Link>
                <span className='dash'>/</span>
                <span>Water Distribution</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="service">
      <div className='row m-10-hor'>
        <div className='col-md-4'>
          <iframe 
            src="https://donorbox.org/embed/donate-to-uhf-1?" 
            name="donorbox" 
            title='donate'
            style={{width:'100%', height:'700px', minHeight:'690px', backgroundColor:'white'}}>
          </iframe> 
        </div>
        <div className='col-md-8'>
          <Images />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
