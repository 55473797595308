import React, { Component } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import Footer from '../../components/footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomSlide from '../../components/ImageGallery/slides';


class Images extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const imageNames = [
      'IMG_E8830.JPG',
      'IMG_E9073.JPG',
      'IMG_E8997.JPG',
      '4.jpeg',
      '5.jpeg',
      '6.jpeg',
      '9.jpeg',
      '10.jpeg',
      '12.jpeg',
      '17.jpeg',
      '18.jpeg',
      '19.jpeg',
      '20.jpeg',
      '21.jpeg',
      '22.jpeg',
      '23.jpeg',
      '24.jpeg',
      '25.jpeg',
      '26.jpeg',
      '27.jpeg',
      '28.jpeg',
      '29.jpeg', 
      '30.jpeg',
      '31.jpeg',
      '32.jpeg', 
      '33.jpeg',
      '34.jpeg',
      '35.jpeg',
      '36.jpeg',
      '37.jpeg',
      '38.jpeg',
      '39.jpeg',
      '40.jpeg',
      '41.jpeg',
      '42.jpeg',
      '43.jpeg',
      '44.jpeg',
      '45.jpeg',
      '46.jpeg',
      '47.jpeg',
      'IMG_0007.JPG',
      'IMG_0028.JPG',
      'IMG_0029.JPG',
      'IMG_0032.JPG',
      'IMG_0348.JPG',
      'IMG_0719.JPG',
      'IMG_0845.JPG',
      'IMG_0871.JPG',
      'IMG_0888.JPG',
      'IMG_0905.JPG',
    ];

    return (
      <div className='slickteam'>
        <Slider {...settings}>
          {imageNames.map((imageName, index) => (
            <CustomSlide
              className='itm'
              index={index}
              key={index}
              imgSrc={`/img/gallery/5eid/${imageName}`}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <div className='wrapperitem'>
    <section className='jumbotron breadcumb' style={{ position: 'relative', overflow: 'hidden', zIndex: 0 }}>
      <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/gallery/5eid/IMG_0871.JPG)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(3px)',
          zIndex: -2,
        }}>
      </div>
      <div className='mainbreadcumb' style={{ position: 'relative', zIndex: 1 }}>
        <div className='container-fluid'>
          <div className='row m-10-hor'>
            <div className='col-md-6'>
              <h1>Eid Clothing</h1>
            </div>
            <div className='col-md-6'>
              <div className='list'>
                <Link className='link' to="/">Home</Link>
                <span className='dash'>/</span>
                <Link className='link' to="/projects">Projects</Link>
                <span className='dash'>/</span>
                <span>Eid Clothing</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='container-fluid' id="service">
      <div className='row m-10-hor'>
        <div className='col-md-4'>
          <iframe 
            src="https://donorbox.org/embed/donate-to-uhf-1?" 
            name="donorbox" 
            title='donate'
            style={{width:'100%', height:'700px', minHeight:'690px', backgroundColor:'white'}}>
          </iframe> 
        </div>
        <div className='col-md-8'>
          <Images />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
